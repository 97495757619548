import Table, { ColumnsType } from "antd/lib/table"
import { WebApiMemberDtoMemberDto, WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto } from "../../Repository/eventbookyapi"
import { Button, Popconfirm, Space } from "antd";
import EditMember from "../Users/EditMember";
import AddNote from "../Users/AddNote";
import AddPayment from "../Users/AddPayment";
import { DeleteOutlined } from "@ant-design/icons";
import { formatDateString } from "../../Helpers/DateHelpers";
import { TextService } from "../../Services/TextService";
import { MemberService } from "../../Services/MemberService";
import { useState } from "react";
import { PaymentConfiguration } from "../../Types/PaymentConfiguration";
import { setCookieLanguage } from "../../Helpers/RouteHelper";
import Cookies from "universal-cookie";

export const MemberTable = (props: any) => {

    const GetText = TextService.GetText;
    const [memberToDelete, setMemberToDelete] = useState(0 as number);

    const sortUniqueId = (function (a: any, b: any) {
        if (Number(a.uniqueId) < Number(b.uniqueId)) { return -1; }
        if (Number(a.uniqueId) > Number(b.uniqueId)) { return 1; }
        return 0;
    })

    const sortLastName = (function (a: any, b: any) {
        if (a.lastName < b.lastName) { return -1; }
        if (a.lastName > b.lastName) { return 1; }
        return 0;
    })

    const sortFirstName = (function (a: any, b: any) {
        if (a.firstName < b.firstName) { return -1; }
        if (a.firstName > b.firstName) { return 1; }
        return 0;
    })

    const sortLastModified = (function (a: any, b: any) {
        if (a.lastModified < b.lastModified) { return -1; }
        if (a.lastModified > b.lastModified) { return 1; }
        return 0;
    })

    const sortCreated = (function (a: any, b: any) {
        if (a.createdAt < b.createdAt) { return -1; }
        if (a.createdAt > b.createdAt) { return 1; }
        return 0;
    })

    const sortEmail = (function (a: any, b: any) {
        if (a.email < b.email) { return -1; }
        if (a.email > b.email) { return 1; }
        return 0;
    })

    const onDeleteClick = (memberId: number) => {
        setMemberToDelete(memberId);
    };

    const handleOk = async () => {
        await MemberService.DeleteMember(memberToDelete);
        props.loadMembers();
        setMemberToDelete(0);
    };

    const onPaginateChange = (value: any, newPageSize: any) => {
        new Cookies().set('customerPageSize', newPageSize, { maxAge: 31536000 });
        props.handlePageSizeChanged(newPageSize);
    };

    const columns: ColumnsType<WebApiServicesDataTransferObjectsGroupServiceGroupMemberDto> = [
        {
            title: GetText("MemberList_UniqueIdColumn"),
            width: 100,
            dataIndex: 'uniqueId',
            key: 'uniqueId',
            render: (_, member: WebApiMemberDtoMemberDto) => (
                <span>{member.uniqueId}<br />
                </span>
            ),
            sorter: sortUniqueId,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: GetText("MemberList_FirstNameColumn"),
            dataIndex: 'firstname',
            key: 'firstname',
            width: 150,
            render: (_, member: WebApiMemberDtoMemberDto) => (
                <span>{member.firstName}<br />
                </span>
            ),
            sorter: sortFirstName,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: GetText("MemberList_LastNameColumn"),
            dataIndex: 'lastname',
            key: 'lastname',
            width: 150,
            render: (_, member: WebApiMemberDtoMemberDto) => (
                <span>{member.lastName}<br />
                </span>
            ),
            sorter: sortLastName,
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: GetText("MemberList_EmailColumn"),
            dataIndex: 'email',
            key: 'email',
            width: 150,
            sorter: sortEmail,
            render: (_, member: WebApiMemberDtoMemberDto) => (
                <span>{member.email}<br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_PhoneColumn"),
            dataIndex: 'phone',
            key: 'phone',
            width: 150,
            render: (_, member: WebApiMemberDtoMemberDto) => (
                <span>{member.phoneNumberFormatted}<br />
                </span>
            ),
        },
        {
            title: GetText("MemberList_GroupsColumn"),
            dataIndex: 'company',
            key: 'company',
            width: 150,
            render: (_, member: WebApiMemberDtoMemberDto) => (
                <span>

                    {member!.groupNames?.map((groupName: any, i) => {
                        return (<>{groupName} <br /></>)
                    })}


                    <br />
                </span>
            ),
        }]

    if (props.isMail == true) {

        columns.push({
            title: GetText("MemberList_ActionColumn"),
            key: 'action',
            render: (_, member) => (
                <Space size="middle">
                    <Button size="small" onClick={() => props.removeMember(member)}>Ta bort</Button>
                </Space>
            )
        })
    }
    else {
        columns.push({
            title: GetText("MemberList_LastModifiedColumn"),
            dataIndex: 'lastModified',
            key: 'lastModified',
            width: 150,
            sorter: sortLastModified,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (_, member: WebApiMemberDtoMemberDto) => (
                <span>{formatDateString(member?.lastModified!.toString())}<br />
                </span>
            ),
        },
            {
                title: GetText("MemberList_CreatedColumn"),
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 150,
                sorter: sortCreated,
                sortDirections: ['ascend', 'descend', 'ascend'],
                render: (_, member: WebApiMemberDtoMemberDto) => (
                    <span>{formatDateString(member?.createdAt!.toString())}<br />
                    </span>
                ),
            },
            {
                title: GetText("MemberList_ActionColumn"),
                key: 'action',
                width: 150,
                render: (_, member) => (
                    <Space size="middle">
                        <EditMember user={props.user} memberFieldSettings={props.memberFieldSettings} loadMembers={props.loadMembers} members={props.members} groups={props.groups} member={member}>Edit</EditMember>
                        <AddNote user={props.user} loadMembers={props.loadMembers} groups={props.groups} member={member}></AddNote>
                        {props.user.paymentConfiguration == PaymentConfiguration.YearlyPayments ? <AddPayment loadMembers={props.loadMembers} groups={props.groups} member={member}></AddPayment> : ""}
                        {!props.user.hasOnlyReadRights ? <DeleteOutlined style={{ color: "red", fontSize: "20px" }} onClick={() => onDeleteClick(member?.memberId!)}></DeleteOutlined> : ""}

                        <Popconfirm
                            title={GetText("MemberList_DeleteMemberConfirmMessage")}
                            key={member.email}
                            open={member.memberId === memberToDelete}
                            placement="topRight"
                            onConfirm={handleOk}
                            onCancel={() => setMemberToDelete(0)}
                        >
                        </Popconfirm>
                    </Space>
                ),
            },)
    }

    return (<Table
        columns={columns}
        style={props.style}
        locale={{ emptyText: GetText("MemberList_EmptyMessage") }}
        className="admin-table"
        rowKey={m => m.memberId!}
        pagination={{ pageSize: props.pageSizePreference, locale: { items_per_page: "/ " + GetText("Common_Page") }, position: ['topRight'], showSizeChanger: true, onShowSizeChange: onPaginateChange }}
        key='memberId'
        dataSource={props.members}
        rowClassName={(record: WebApiMemberDtoMemberDto, index) => record.status == 1 ? "cancelled-member" : ""}
    />);
}