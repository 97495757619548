/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebApiAdminDtoUserDto } from './WebApiAdminDtoUserDto';
import {
    WebApiAdminDtoUserDtoFromJSON,
    WebApiAdminDtoUserDtoFromJSONTyped,
    WebApiAdminDtoUserDtoToJSON,
} from './WebApiAdminDtoUserDto';

/**
 * 
 * @export
 * @interface WebApiAdminDtoUserListResultDto
 */
export interface WebApiAdminDtoUserListResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof WebApiAdminDtoUserListResultDto
     */
    hasFullAccess?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WebApiAdminDtoUserListResultDto
     */
    numberOfUsers?: number;
    /**
     * 
     * @type {Array<WebApiAdminDtoUserDto>}
     * @memberof WebApiAdminDtoUserListResultDto
     */
    users?: Array<WebApiAdminDtoUserDto> | null;
}

/**
 * Check if a given object implements the WebApiAdminDtoUserListResultDto interface.
 */
export function instanceOfWebApiAdminDtoUserListResultDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebApiAdminDtoUserListResultDtoFromJSON(json: any): WebApiAdminDtoUserListResultDto {
    return WebApiAdminDtoUserListResultDtoFromJSONTyped(json, false);
}

export function WebApiAdminDtoUserListResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebApiAdminDtoUserListResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasFullAccess': !exists(json, 'hasFullAccess') ? undefined : json['hasFullAccess'],
        'numberOfUsers': !exists(json, 'numberOfUsers') ? undefined : json['numberOfUsers'],
        'users': !exists(json, 'users') ? undefined : (json['users'] === null ? null : (json['users'] as Array<any>).map(WebApiAdminDtoUserDtoFromJSON)),
    };
}

export function WebApiAdminDtoUserListResultDtoToJSON(value?: WebApiAdminDtoUserListResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasFullAccess': value.hasFullAccess,
        'numberOfUsers': value.numberOfUsers,
        'users': value.users === undefined ? undefined : (value.users === null ? null : (value.users as Array<any>).map(WebApiAdminDtoUserDtoToJSON)),
    };
}

