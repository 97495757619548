import { Collapse } from 'antd';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import Page from '../../Components/Page/Page';
import BookyBox from '../../Components/Layout/BookyBox';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';

const GDPR = () => {
    const GetText = TextService.GetText;


    const textSE = <span>Som kund hos Onefront360 ska man alltid känna sig trygg med hur vi hanterar era personuppgifter. Vi följer EU:s dataskyddsförordning (GDPR) i vår hantering av personuppgifter från och med den 25 maj 2018.<br />
        GDPR ställer högre krav på oss som företag, vilket vi ser positivt på, det innebär dock ingen märkbar skillnad för kunden. Vi värnar redan om personlig integritet och anser att det är viktigt att hålla en fortsatt hög skyddsnivå för personuppgifter.<br />
        I vår del av uppdraget säkerställer vi att våra rutiner, medarbetare och underleverantörer hanterar personuppgifter i enlighet med GDPR.<br /><br />
        Har du frågor kring processen för GDPR, kontakta supporten OneFront360.</span>

    const textEN = <span>As a customer of Onefront360, you should always feel safe with how we handle your personal data. We comply with the EU's data protection regulation (GDPR) in our handling of personal data as of May 25, 2018.<br />
        GDPR places higher demands on us as a company, which we view positively, however, it does not mean a noticeable difference for the customer. We already protect personal privacy and consider it important to maintain a continued high level of protection for personal data.<br />
        In our part of the assignment, we ensure that our routines, employees and subcontractors handle personal data in accordance with the GDPR.<br /><br />
        If you have questions about the GDPR process, contact OneFront360 support.</span>

    return <Page pageTitle={GetText("PageTitle_GDPR")}>
        <BookyBox>
            <Title>{GetText("PageHeader_GDPR")}</Title>
            <Paragraph>
                {getCurrentLanguage() === "se" ? textSE : textEN}
            </Paragraph>
        </BookyBox>
    </Page>
};

export default GDPR;




