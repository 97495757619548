import { useEffect, useState } from 'react';
import { Menu, MenuProps } from 'antd';
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import { UserOutlined, TeamOutlined, UserAddOutlined, BarChartOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { CompanyRole } from '../../Types/CompanyRole';

const LeftMenuCommunicate = (props: any) => {
    const [current, setCurrent] = useState('1');
    const [user, setUser] = useState({} as AuthenticatedUser | null);
    const GetText = TextService.GetText;
    const navigation = useNavigate();
    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            setUser(user);
        });

        setCurrent(props.selected);
    }, []);

    type MenuItem = Required<MenuProps>['items'][number];

    const onClick: MenuProps['onClick'] = (e: any) => {
        console.log("navigate" + getCurrentLanguage());
        navigation("/" + getCurrentLanguage() + "/" + e.key);
    };

    function getItem(
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    const sendMailItems: MenuItem[] = [
        getItem(GetText("Communicate_SendCategoryTitle"), 'sendEmail', <TeamOutlined />),
        getItem(GetText("Communicate_SendMemberTitle"), 'sendEmailUser', <UserOutlined />),
        getItem(GetText("Communicate_SendExternalTitle"), 'sendEmailExternal', <UserAddOutlined />),
    ];

    const sendSmsItems: MenuItem[] = [
        getItem(GetText("Communicate_SendCategoryTitle"), 'sendSms', <TeamOutlined />),
        getItem(GetText("Communicate_SendMemberTitle"), 'sendSmsUser', <UserOutlined />),
        getItem(GetText("Communicate_SendExternalTitle"), 'sendSmsExternal', <UserAddOutlined />),
    ];

    const items: MenuItem[] = user?.role == CompanyRole.GlobalAdmin ? [
        getItem(GetText("Communicate_SendMailTitle"), 'sendSmsHeader', <TeamOutlined />, sendMailItems, "group"),
        getItem(GetText("Communicate_SendSmsTitle"), 'sendSmsUserHeader', <TeamOutlined />, sendSmsItems, "group"),
        getItem(GetText("EmailLogs_EmailLogsTitle"), 'emailLogs', <BarChartOutlined />),

    ] : [
    ];

    return <Menu
        theme="light"
        onClick={onClick}
        defaultOpenKeys={['sendSmsHeader', 'sendSmsHeader']}
        inlineCollapsed={false}
        selectedKeys={[current]}
        mode="inline"
        items={items}
        disabledOverflow={true}
    />
};

export default LeftMenuCommunicate;