/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BlobDto
 */
export interface BlobDto {
    /**
     * 
     * @type {string}
     * @memberof BlobDto
     */
    uri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlobDto
     */
    relativeUri?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlobDto
     */
    isPublicImage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BlobDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlobDto
     */
    contentType?: string | null;
    /**
     * 
     * @type {Blob}
     * @memberof BlobDto
     */
    content?: Blob | null;
}

/**
 * Check if a given object implements the BlobDto interface.
 */
export function instanceOfBlobDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BlobDtoFromJSON(json: any): BlobDto {
    return BlobDtoFromJSONTyped(json, false);
}

export function BlobDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlobDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uri': !exists(json, 'uri') ? undefined : json['uri'],
        'relativeUri': !exists(json, 'relativeUri') ? undefined : json['relativeUri'],
        'isPublicImage': !exists(json, 'isPublicImage') ? undefined : json['isPublicImage'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'content': !exists(json, 'content') ? undefined : json['content'],
    };
}

export function BlobDtoToJSON(value?: BlobDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uri': value.uri,
        'relativeUri': value.relativeUri,
        'isPublicImage': value.isPublicImage,
        'name': value.name,
        'contentType': value.contentType,
        'content': value.content,
    };
}

