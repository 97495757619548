import { useEffect } from 'react';
import Login from './Pages/Login/Login';
import { Route, Routes, useLocation } from "react-router-dom";
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Start from './Pages/Start/Start';
import Create from './Pages/Create/Create';
import Event from './Pages/Event/Event';
import EventAnsweredComplete from './Pages/Event/EventAnsweredComplete';
import CreateUser from './Pages/NewUser/CreateUser';
import NewUserCreated from './Pages/NewUser/NewUserCreated';
import AdminStart from './PagesAdmin/Start/Start';
import MyEvents from './PagesAdmin/Events/MyEvents';
import MyEventDetails from './PagesAdmin/Events/MyEventDetails';
import Pricing from './Pages/Pricing/Pricing';
import ConfirmEmail from './Pages/ConfirmEmail/ConformEmail';
import MyEventEdit from './PagesAdmin/Events/MyEventEdit';
import NewUserCreatedWithProvider from './Pages/NewUser/NewUserCreatedWithProvider';
import Terms from './Pages/Terms/Terms';
import Contact from './Pages/Contact/Contact';
import PurchaseComplete from './Pages/Pricing/PurchaseComplete';
import Settings from './PagesAdmin/Account/Settings';
import FinalizePremiumRegistration from './Pages/FinalizePayment/FinalizeRegistration';
import ResetPassword from './Pages/ForgotPassword/ResetPassword';
import AdminGroups from './PagesAdmin/Users/Groups';
import Privacy from './Pages/Privacy/Privacy';
import ReactGA from 'react-ga'
import AdminPage from './Admin/AdminPage';
import AdminUsers from './PagesAdmin/Users/Users';
import Administrators from './PagesAdmin/Account/Admins';
import CreateCompanyUser from './Pages/NewUser/CreateCompanyUser';
import PublicSite from './PagesAdmin/Account/PublicSite';
import PublicSiteEditMember from './Pages/PublicSite/PublicSiteEditMember';
import PublicSiteNewMember from './Pages/PublicSite/PublicSiteNewMember';
import MemberSettings from './PagesAdmin/Account/MemberSettings';
import AdminSendEmailCategory from './PagesAdmin/Communicate/SendEmailCategory';
import AdminSendEmailMember from './PagesAdmin/Communicate/SendEmailMember';
import UploadMembers from './Components/Users/UploadMembers/UploadMembers';
import UploadPayments from './Components/Users/UploadPayments/UploadPayments';
import PublicSiteNewMemberActivateAccount from './Pages/PublicSite/NewMemberActiveteAccount';
import PublicSiteResult from './Pages/PublicSite/PublicSiteResultPage';
import AdminEmailTemplates from './PagesAdmin/Account/EmailTemplates';
import AdminSendEmailExternal from './PagesAdmin/Communicate/SendEmailExternal';
import AdminEmailLogs from './PagesAdmin/Communicate/EmailLogs';
import AdminUserHistory from './PagesAdmin/Users/UserHistory';
import Payments from './PagesAdmin/Account/Payment';
import PaymentConfirmation from './PagesAdmin/Account/PaymentConfirmation';
import FeaturesMemberOrganisations from './Pages/Features/FeaturesMemberOrganisations';
import FeaturesCompanies from './Pages/Features/FeaturesCompanies';
import MyEventDetailsParticipants from './PagesAdmin/Events/MyEventDetailsParticipants';
import MyEventDetailsSendInviteExternal from './PagesAdmin/Events/MyEventDetailsSendInviteExternal';
import MyEventDetailsSendInviteGroup from './PagesAdmin/Events/MyEventDetailsSendInviteGroup';
import MyEventDetailsSendInviteMember from './PagesAdmin/Events/MyEventDetailsSendInviteMember';
import Terms_En from './Pages/Terms/Terms_en';
import Privacy_En from './Pages/Privacy/Privacy_En';
import MyEventBranding from './PagesAdmin/Events/MyEventsBranding';
import MyEventDetailsReminder from './PagesAdmin/Events/MyEventDetailsReminder';
import AdminIntegrations from './PagesAdmin/Account/Integrations';
import AdminSendSmsCategory from './PagesAdmin/Communicate/SendSmsCategory';
import AdminSendSmsExternal from './PagesAdmin/Communicate/SendSmsExternal';
import AdminSendSmsMember from './PagesAdmin/Communicate/SendSmsMember';
import GDPR from './Pages/GDPR/GDPR';
import PartnerFortnox from './Pages/Partners/Fortnox';

const NavigationRoutes = () => {
    ReactGA.initialize("G-BWZH8XKY95");
    const location = useLocation();
    useEffect(() => {
        if (window.location.href.indexOf("onefront360.com") != -1) {
            ReactGA.set({ page: location.pathname })
            ReactGA.pageview(location.pathname)
        }
    }, [location]);

    const MultiRoute = (element: JSX.Element, paths: string[]) => {
        return paths.map((p) => <Route key={p} element={element} path={p} />);
    };

    return (
        <Routes>
            {MultiRoute(<Start />, ["/", "/:lang/"])}
            {MultiRoute(<Login />, ["/Login", "/:lang/Login"])}
            {MultiRoute(<Login />, ["/Logout", "/:lang/Logout"])}
            {MultiRoute(<Login />, ["/Login/error/:errorid", "/:lang/Login/error/:errorid"])}
            {MultiRoute(<ForgotPassword />, ["/ForgotPassword", "/:lang/ForgotPassword/"])}
            {MultiRoute(<ResetPassword />, ["/ResetPassword", "/:lang/ResetPassword/"])}
            {MultiRoute(<CreateUser />, ["/CreateUser", "/:lang/CreateUser/"])}
            {MultiRoute(<CreateCompanyUser />, ["/CreateCompanyUser/:companyInviteId", "/:lang/CreateCompanyUser/:companyInviteId"])}
            {MultiRoute(<CreateUser />, ["/CreateUser/error/:errorid", "/:lang/CreateUser/error/:errorid"])}
            {MultiRoute(<NewUserCreated />, ["/NewUserCreated", "/:lang/NewUserCreated"])}
            {MultiRoute(<NewUserCreatedWithProvider />, ["/NewUserCreatedWithProvider", "/:lang/NewUserCreatedWithProvider"])}
            {MultiRoute(<FinalizePremiumRegistration />, ["/finalizepremiumregistration", "/:lang/finalizepremiumregistration"])}
            {MultiRoute(<Create />, ["/create", "/:lang/create"])}
            {MultiRoute(<Event />, ["/Event/:id", "/:lang/Event/:id"])}
            {MultiRoute(<Event />, ["/Event/:companyurl/:id", "/:lang/:companyurl/Event/:id"])}
            {MultiRoute(<Event />, ["/:companyurl/Event/:id/invite/:inviteId", "/:lang/:companyurl/Event/:id/invite/:inviteId"])}
            {MultiRoute(<Event />, ["/Event/:id/invite/:inviteId", "/:lang/Event/:id/invite/:inviteId"])}
            {MultiRoute(<Event />, ["/:companyurl/Event/:id/reply/:authCode", "/:lang/:companyurl/Event/:id/reply/:authCode"])}
            {MultiRoute(<Event />, ["/Event/:id/reply/:authCode", "/:lang/Event/:id/reply/:authCode"])}
            {MultiRoute(<EventAnsweredComplete />, ["/Event/Completed", "/:lang/Event/Completed"])}
            {MultiRoute(<AdminStart />, ["/admin/start", "/:lang/admin/start"])}
            {MultiRoute(<AdminGroups />, ["/groups", "/:lang/groups"])}
            {MultiRoute(<AdminUsers />, ["/users", "/:lang/users"])}
            {MultiRoute(<AdminUserHistory />, ["/userHistory", "/:lang/userHistory"])}
            {MultiRoute(<Administrators />, ["/admins", "/:lang/admins"])}
            {MultiRoute(<AdminSendEmailCategory />, ["/sendEmail", "/:lang/sendEmail"])}
            {MultiRoute(<AdminSendEmailMember />, ["/sendEmailUser", "/:lang/sendEmailUser"])}
            {MultiRoute(<AdminSendEmailExternal />, ["/sendEmailExternal", "/:lang/sendEmailExternal"])}
            {MultiRoute(<AdminEmailLogs />, ["/emailLogs", "/:lang/emailLogs"])}
            {MultiRoute(<MyEvents />, ["/myevents", "/:lang/myevents/"])}
            {MultiRoute(<MyEvents />, ["/myevents/created/:newid", "/:lang/myevents/created/:newid"])}
            {MultiRoute(<MyEventDetails />, ["/myevents/:id", "/:lang/myevents/:id"])}
            {MultiRoute(<MyEventDetailsParticipants />, ["/myevents/:id/particpants", "/:lang/myevents/:id/participants"])}
            {MultiRoute(<MyEventDetailsSendInviteExternal />, ["/myevents/:id/sendinviteexternal", "/:lang/myevents/:id/sendinviteexternal"])}
            {MultiRoute(<MyEventDetailsSendInviteGroup />, ["/myevents/:id/sendinvitegroup", "/:lang/myevents/:id/sendinvitegroup"])}
            {MultiRoute(<MyEventDetailsSendInviteMember />, ["/myevents/:id/sendinvitemember", "/:lang/myevents/:id/sendinvitemember"])}
            {MultiRoute(<MyEventDetailsReminder />, ["/myevents/:id/reminders", "/:lang/myevents/:id/reminders"])}
            {MultiRoute(<MyEventEdit />, ["/myevents/:id/edit", "/:lang/myevents/:id/edit"])}
            {MultiRoute(<MyEventBranding />, ["/myevents/:id/branding", "/:lang/myevents/:id/branding"])}
            {MultiRoute(<Pricing />, ["/pricing", "/:lang/pricing"])}
            {MultiRoute(<PurchaseComplete />, ["/pricing/purchasecomplete", "/:lang/pricing/purchasecomplete"])}
            {MultiRoute(<Settings />, ["/settings", "/:lang/settings"])}
            {MultiRoute(<MemberSettings />, ["/memberSettings", "/:lang/memberSettings"])}
            {MultiRoute(<ConfirmEmail />, ["/confirmEmail/:code", "/:lang/confirmEmail/:code"])}
            {MultiRoute(<Terms_En />, ["/terms", "/en/terms"])}
            {MultiRoute(<Terms />, ["/se/terms"])}
            {MultiRoute(<Contact />, ["/contact/", "/:lang/contact"])}
            {MultiRoute(<GDPR />, ["/gdpr/", "/:lang/gdpr"])}
            {MultiRoute(<Privacy_En />, ["/privacy-policy", "/:lang/privacy-policy"])}
            {MultiRoute(<Privacy />, ["/se/privacy-policy"])}
            {MultiRoute(<AdminPage />, ["/admin", "/:lang/admin"])}
            {MultiRoute(<AdminEmailTemplates />, ["/emailTemplates", "/:lang/emailTemplates"])}
            {MultiRoute(<PublicSite />, ["/publicsite", "/:lang/publicsite"])}
            {MultiRoute(<UploadMembers />, ["/:companyurl/uploadmembers", "/:lang/:companyurl/uploadmembers"])}
            {MultiRoute(<UploadPayments />, ["/:companyurl/uploadPayments", "/:lang/:companyurl/uploadPayments"])}
            {MultiRoute(<PublicSiteEditMember />, ["/:companyurl/editmember", "/:lang/:companyurl/editmember"])}
            {MultiRoute(<PublicSiteEditMember />, ["/:companyurl/editmember/:passwordcode", "/:lang/:companyurl/editmember/:passwordcode"])}
            {MultiRoute(<PublicSiteNewMember />, ["/:companyurl/newmember", "/:lang/:companyurl/newmember"])}
            {MultiRoute(<PublicSiteNewMemberActivateAccount />, ["/:companyurl/newmemberactiveaccount/:passwordcode", "/:lang/:companyurl/newmemberactiveaccount/:passwordcode"])}
            {MultiRoute(<PublicSiteResult />, ["/:companyurl/succes/:successCode", "/:lang/:companyurl/success/:successCode"])}
            {MultiRoute(<Payments />, ["/payments/", "/:lang/payments"])}
            {MultiRoute(<PaymentConfirmation />, ["/payments/confirmation/:id", "/:lang/payments/confirmation/:id"])}
            {MultiRoute(<FeaturesMemberOrganisations />, ["/features/organisations", "/:lang/features/organisations"])}
            {MultiRoute(<FeaturesCompanies />, ["/features/companies", "/:lang/features/companies"])}
            {MultiRoute(<AdminIntegrations />, ["/integrations", "/:lang/integrations"])}
            {MultiRoute(<AdminIntegrations />, ["/integrations", "/:lang/integrations"])}
            {MultiRoute(<AdminIntegrations />, ["/integrations", "/:lang/integrations"])}
            {MultiRoute(<AdminSendSmsCategory />, ["/sendSms", "/:lang/sendSms"])}
            {MultiRoute(<AdminSendSmsMember />, ["/sendSmsUser", "/:lang/sendSmsUser"])}
            {MultiRoute(<AdminSendSmsExternal />, ["/sendSmsExternal", "/:lang/sendSmsExternal"])}
            {MultiRoute(<PartnerFortnox />, ["/fortnox-integration", "/:lang/fortnox-integration"])}
        </Routes>);
};

export default NavigationRoutes;