import { useEffect, useState } from 'react';
import { Button, Col, Divider, Layout, Popconfirm, Row, Space, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useNavigate } from "react-router-dom";
import { ColumnsType } from 'antd/lib/table';
import { AdminService } from '../Services/AdminService';
import { formatDateString, formatToShortFormat } from '../Helpers/DateHelpers';
import { AuthProvider } from '../Providers/authProvider';
import { getCurrentLanguage } from '../Helpers/RouteHelper';
import { CompanyRole } from '../Types/CompanyRole';
import { SubscriptionLevel } from '../Types/SubscriptionLevel';
import { message } from "antd"
import Page from '../Components/Page/Page';
import { mapSubscriptionLevel } from '../Mappers/mapSubscriptionLevel';

const AdminPage = () => {
    const navigation = useNavigate();
    const [users, setUsers] = useState([] as any);
    const [companyToDeleteMembers, setCompanyToDeleteMembers] = useState(0 as number);
    const [hasFullAccess, setHasFullAccess] = useState(false as boolean);
    useEffect(() => {
        AuthProvider().GetLoggedInUser().then(user => {
            if (user === null) {
                navigation("/" + getCurrentLanguage());
            }
            loadEvents();
        });

    }, []);

    const loadEvents = () => {
        AdminService.GetAllUsers().then(result => {
            const users: ColumnsType<DataType> = result.users as any;
            setHasFullAccess(result.hasFullAccess!);
            setUsers(users);
        }).catch(result => {
            navigation("/" + getCurrentLanguage());
        })
    }

    const onEditClick = async (userId: number) => {
        const loginResponse = await AdminService.SetUserAsAdmin(userId);

        if (loginResponse.token != null) {
            AuthProvider().SetUser({
                id: loginResponse.id!,
                firstName: loginResponse.firstName!,
                lastName: loginResponse.lastName!,
                email: loginResponse.email!,
                token: loginResponse.token!,
                tokenExpires: loginResponse.tokenExpires!,
                companyId: loginResponse.companyId!,
                companyLanguage: loginResponse.companyLanguage!,
                subscriptionLevel: loginResponse.subscriptionLevel!,
                hasPaidSubscription: loginResponse.subscriptionLevel! != SubscriptionLevel.Free,
                role: loginResponse.role!,
                paymentConfiguration: loginResponse.paymentConfiguration!,
                companyType: loginResponse.companyType!,
                hasOnlyReadRights: loginResponse.role === CompanyRole.MemberControlAdmin,
                maxAdministrators: loginResponse.maxAdministrators!,
                maxEmails: loginResponse.maxEmails!,
                maxMembers: loginResponse.maxMembers!,
                smsCredits: loginResponse.smsCredits!
            });
            navigation("/" + getCurrentLanguage());
        }
    }

    const handleDeleteMembersConfirm = async () => {
        await AdminService.DeleteAllCompanyMembers(companyToDeleteMembers);
        setCompanyToDeleteMembers(0);
        loadEvents();
        message.success("Medlemmar är borttagna");
    };

    interface DataType {
        key: string;
        userId: number;
        email: string;
        fullName: string;
        company: string;
        companyId: number;
        memberCount: string;
        subscriptionLevel: SubscriptionLevel;
        nextSubscriptionDate: Date;
        created: string;
    }


    const columns: ColumnsType<DataType> = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, user) => (
                <b>{user.email}</b>
            ),
        },
        {
            title: 'Compmany name',
            dataIndex: 'company',
            key: 'company',
            render: (_, user) => (
                <b>{user.company}</b>
            ),
        },
        {
            title: 'Fullname',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (_, user) => (
                <b>{user.fullName}</b>
            ),
        },
        {
            title: 'Membercount',
            dataIndex: 'membercount',
            key: 'membercount',
            render: (_, user) => (
                <b>{user.memberCount}</b>
            ),
        },
        {
            title: 'Accountlevel',
            dataIndex: 'subscriptionLevel',
            key: 'subscriptionLevel',
            render: (_, user) => (
                <b>{mapSubscriptionLevel(user.subscriptionLevel)}</b>
            ),
        },
        {
            title: 'Next subscription date',
            dataIndex: 'nextSubscriptionDate',
            key: 'nextSubscriptionDate',
            render: (_, user) => (
                <b>{formatToShortFormat(user.nextSubscriptionDate)}</b>
            ),
        },


        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (_, user) => (
                <b>{formatDateString(user.created)}</b>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, user) => (
                <Space size="middle">
                    <Button onClick={() => onEditClick(user.userId)} type="primary">Open</Button>

                    <Popconfirm
                        title={"Är du säker, alla medlemmar kommer tas bort för företaget (" + user.company + ")?"}
                        key={user.userId}
                        open={user.companyId === companyToDeleteMembers}
                        placement="topRight"
                        onConfirm={handleDeleteMembersConfirm}
                        onCancel={() => setCompanyToDeleteMembers(0)}
                    >
                    </Popconfirm>
                    {hasFullAccess ? <Button danger onClick={() => setCompanyToDeleteMembers(user.companyId)}>Delete members</Button> : ""}
                </Space>
            ),
        },
    ];

    return (
        <Page>
            <Row justify="center" align="top">
                <Col span={16} className='bookyBox'>
                    <Title>OneFront 360 Admin Page</Title>
                    <br />
                    <Table columns={columns} className="admin-table" rowKey="Id" dataSource={users} />
                </Col>
            </Row>
        </Page>);
};

export default AdminPage;