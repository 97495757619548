/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebApiMemberDtoAddBulkMemberDto,
  WebApiMemberDtoGetPaymentDto,
  WebApiMemberDtoMemberDto,
  WebApiMemberDtoMemberHistoryDto,
  WebApiMemberDtoMemberListResultDto,
  WebApiMemberFieldsDtoSaveMemberFieldSettingsDto,
  WebApiModelsMemberField,
  WebApiModelsNote,
  WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData,
  WebApiServicesDataTransferObjectsGroupServiceAddMemberDto,
  WebApiServicesDataTransferObjectsGroupServiceAddNoteDto,
  WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto,
  WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto,
  WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto,
} from '../models';
import {
    WebApiMemberDtoAddBulkMemberDtoFromJSON,
    WebApiMemberDtoAddBulkMemberDtoToJSON,
    WebApiMemberDtoGetPaymentDtoFromJSON,
    WebApiMemberDtoGetPaymentDtoToJSON,
    WebApiMemberDtoMemberDtoFromJSON,
    WebApiMemberDtoMemberDtoToJSON,
    WebApiMemberDtoMemberHistoryDtoFromJSON,
    WebApiMemberDtoMemberHistoryDtoToJSON,
    WebApiMemberDtoMemberListResultDtoFromJSON,
    WebApiMemberDtoMemberListResultDtoToJSON,
    WebApiMemberFieldsDtoSaveMemberFieldSettingsDtoFromJSON,
    WebApiMemberFieldsDtoSaveMemberFieldSettingsDtoToJSON,
    WebApiModelsMemberFieldFromJSON,
    WebApiModelsMemberFieldToJSON,
    WebApiModelsNoteFromJSON,
    WebApiModelsNoteToJSON,
    WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentDataFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentDataToJSON,
    WebApiServicesDataTransferObjectsGroupServiceAddMemberDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceAddMemberDtoToJSON,
    WebApiServicesDataTransferObjectsGroupServiceAddNoteDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceAddNoteDtoToJSON,
    WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDtoToJSON,
    WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDtoFromJSON,
    WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDtoToJSON,
    WebApiServicesDataTransferObjectsMemberServiceQueryMemberDtoFromJSON,
    WebApiServicesDataTransferObjectsMemberServiceQueryMemberDtoToJSON,
} from '../models';

export interface ApiMemberAddMemberPostRequest {
    webApiServicesDataTransferObjectsGroupServiceAddMemberDto?: WebApiServicesDataTransferObjectsGroupServiceAddMemberDto;
}

export interface ApiMemberAddMembersInBulkPostRequest {
    webApiMemberDtoAddBulkMemberDto?: WebApiMemberDtoAddBulkMemberDto;
}

export interface ApiMemberAddpaymentsInBulkPostRequest {
    webApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData?: WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData;
}

export interface ApiMemberGetFilteredMembersPostRequest {
    webApiServicesDataTransferObjectsMemberServiceQueryMemberDto?: WebApiServicesDataTransferObjectsMemberServiceQueryMemberDto;
}

export interface ApiMemberGetPaymentsGetRequest {
    memberId?: number;
}

export interface ApiMemberMemberIdDeleteRequest {
    memberId: number;
}

export interface ApiMemberMemberIdNotesGetRequest {
    memberId: number;
}

export interface ApiMemberNoteNoteIdDeleteRequest {
    noteId: number;
}

export interface ApiMemberNotePostRequest {
    webApiServicesDataTransferObjectsGroupServiceAddNoteDto?: WebApiServicesDataTransferObjectsGroupServiceAddNoteDto;
}

export interface ApiMemberPaymentPaymentIdDeleteRequest {
    paymentId: number;
}

export interface ApiMemberSaveMemberFieldsPostRequest {
    webApiMemberFieldsDtoSaveMemberFieldSettingsDto?: WebApiMemberFieldsDtoSaveMemberFieldSettingsDto;
}

export interface ApiMemberSavePaymentPostRequest {
    webApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto?: WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto;
}

export interface ApiMemberSendMailToMembersPostRequest {
    files?: Array<Blob>;
    subject?: string;
    message?: string;
    memberIds?: Array<number>;
}

export interface ApiMemberSendSmsToMembersPostRequest {
    text?: string;
    memberIds?: Array<number>;
}

export interface ApiMemberUpdateMemberPostRequest {
    webApiServicesDataTransferObjectsGroupServiceUpdateMemberDto?: WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDto;
}

/**
 * 
 */
export class MemberApi extends runtime.BaseAPI {

    /**
     * 
     */
    async apiMemberAddMemberPostRaw(requestParameters: ApiMemberAddMemberPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/AddMember`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsGroupServiceAddMemberDtoToJSON(requestParameters.webApiServicesDataTransferObjectsGroupServiceAddMemberDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 
     */
    async apiMemberAddMemberPost(requestParameters: ApiMemberAddMemberPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiMemberAddMemberPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiMemberAddMembersInBulkPostRaw(requestParameters: ApiMemberAddMembersInBulkPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/AddMembersInBulk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiMemberDtoAddBulkMemberDtoToJSON(requestParameters.webApiMemberDtoAddBulkMemberDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiMemberAddMembersInBulkPost(requestParameters: ApiMemberAddMembersInBulkPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiMemberAddMembersInBulkPostRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async apiMemberAddpaymentsInBulkPostRaw(requestParameters: ApiMemberAddpaymentsInBulkPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/AddpaymentsInBulk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsGroupServiceAddBulkPaymentDataToJSON(requestParameters.webApiServicesDataTransferObjectsGroupServiceAddBulkPaymentData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiMemberAddpaymentsInBulkPost(requestParameters: ApiMemberAddpaymentsInBulkPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiMemberAddpaymentsInBulkPostRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async apiMemberGenerateExcelFromMembersGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/generateExcelFromMembers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiMemberGenerateExcelFromMembersGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiMemberGenerateExcelFromMembersGetRaw(initOverrides);
    }

    /**
     * Creates new subscription
     */
    async apiMemberGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiMemberDtoMemberListResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiMemberDtoMemberListResultDtoFromJSON(jsonValue));
    }

    /**
     * Creates new subscription
     */
    async apiMemberGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiMemberDtoMemberListResultDto> {
        const response = await this.apiMemberGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Creates new subscription
     */
    async apiMemberGetFilteredMembersPostRaw(requestParameters: ApiMemberGetFilteredMembersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebApiMemberDtoMemberDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/GetFilteredMembers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsMemberServiceQueryMemberDtoToJSON(requestParameters.webApiServicesDataTransferObjectsMemberServiceQueryMemberDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebApiMemberDtoMemberDtoFromJSON));
    }

    /**
     * Creates new subscription
     */
    async apiMemberGetFilteredMembersPost(requestParameters: ApiMemberGetFilteredMembersPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebApiMemberDtoMemberDto>> {
        const response = await this.apiMemberGetFilteredMembersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiMemberGetMemberFieldsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebApiModelsMemberField>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/GetMemberFields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebApiModelsMemberFieldFromJSON));
    }

    /**
     * 
     */
    async apiMemberGetMemberFieldsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebApiModelsMemberField>> {
        const response = await this.apiMemberGetMemberFieldsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiMemberGetPaymentsGetRaw(requestParameters: ApiMemberGetPaymentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebApiMemberDtoGetPaymentDto>> {
        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/GetPayments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebApiMemberDtoGetPaymentDtoFromJSON(jsonValue));
    }

    /**
     * 
     */
    async apiMemberGetPaymentsGet(requestParameters: ApiMemberGetPaymentsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebApiMemberDtoGetPaymentDto> {
        const response = await this.apiMemberGetPaymentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiMemberMemberHistoryByCompanyGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebApiMemberDtoMemberHistoryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/memberHistoryByCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebApiMemberDtoMemberHistoryDtoFromJSON));
    }

    /**
     * 
     */
    async apiMemberMemberHistoryByCompanyGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebApiMemberDtoMemberHistoryDto>> {
        const response = await this.apiMemberMemberHistoryByCompanyGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiMemberMemberIdDeleteRaw(requestParameters: ApiMemberMemberIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling apiMemberMemberIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/{memberId}`.replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiMemberMemberIdDelete(requestParameters: ApiMemberMemberIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiMemberMemberIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async apiMemberMemberIdNotesGetRaw(requestParameters: ApiMemberMemberIdNotesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebApiModelsNote>>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling apiMemberMemberIdNotesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/{memberId}/notes`.replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebApiModelsNoteFromJSON));
    }

    /**
     * 
     */
    async apiMemberMemberIdNotesGet(requestParameters: ApiMemberMemberIdNotesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebApiModelsNote>> {
        const response = await this.apiMemberMemberIdNotesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiMemberNoteNoteIdDeleteRaw(requestParameters: ApiMemberNoteNoteIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling apiMemberNoteNoteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/note/{noteId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 
     */
    async apiMemberNoteNoteIdDelete(requestParameters: ApiMemberNoteNoteIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiMemberNoteNoteIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiMemberNotePostRaw(requestParameters: ApiMemberNotePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/note`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsGroupServiceAddNoteDtoToJSON(requestParameters.webApiServicesDataTransferObjectsGroupServiceAddNoteDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 
     */
    async apiMemberNotePost(requestParameters: ApiMemberNotePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiMemberNotePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiMemberPaymentPaymentIdDeleteRaw(requestParameters: ApiMemberPaymentPaymentIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling apiMemberPaymentPaymentIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/payment/{paymentId}`.replace(`{${"paymentId"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 
     */
    async apiMemberPaymentPaymentIdDelete(requestParameters: ApiMemberPaymentPaymentIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiMemberPaymentPaymentIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiMemberSaveMemberFieldsPostRaw(requestParameters: ApiMemberSaveMemberFieldsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/SaveMemberFields`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiMemberFieldsDtoSaveMemberFieldSettingsDtoToJSON(requestParameters.webApiMemberFieldsDtoSaveMemberFieldSettingsDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 
     */
    async apiMemberSaveMemberFieldsPost(requestParameters: ApiMemberSaveMemberFieldsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiMemberSaveMemberFieldsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async apiMemberSavePaymentPostRaw(requestParameters: ApiMemberSavePaymentPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/SavePayment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDtoToJSON(requestParameters.webApiServicesDataTransferObjectsGroupServiceAddOrUpdatePaymentDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiMemberSavePaymentPost(requestParameters: ApiMemberSavePaymentPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiMemberSavePaymentPostRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async apiMemberSendMailToMembersPostRaw(requestParameters: ApiMemberSendMailToMembersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files', element as any);
            })
        }

        if (requestParameters.subject !== undefined) {
            formParams.append('Subject', requestParameters.subject as any);
        }

        if (requestParameters.message !== undefined) {
            formParams.append('Message', requestParameters.message as any);
        }

        if (requestParameters.memberIds) {
            requestParameters.memberIds.forEach((element) => {
                formParams.append('memberIds', element as any);
            })
        }

        const response = await this.request({
            path: `/api/Member/SendMailToMembers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiMemberSendMailToMembersPost(requestParameters: ApiMemberSendMailToMembersPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiMemberSendMailToMembersPostRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async apiMemberSendSmsToMembersPostRaw(requestParameters: ApiMemberSendSmsToMembersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.text !== undefined) {
            formParams.append('Text', requestParameters.text as any);
        }

        if (requestParameters.memberIds) {
            requestParameters.memberIds.forEach((element) => {
                formParams.append('memberIds', element as any);
            })
        }

        const response = await this.request({
            path: `/api/Member/SendSmsToMembers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiMemberSendSmsToMembersPost(requestParameters: ApiMemberSendSmsToMembersPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiMemberSendSmsToMembersPostRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async apiMemberUpdateMemberPostRaw(requestParameters: ApiMemberUpdateMemberPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Member/updateMember`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebApiServicesDataTransferObjectsGroupServiceUpdateMemberDtoToJSON(requestParameters.webApiServicesDataTransferObjectsGroupServiceUpdateMemberDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async apiMemberUpdateMemberPost(requestParameters: ApiMemberUpdateMemberPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiMemberUpdateMemberPostRaw(requestParameters, initOverrides);
    }

}
